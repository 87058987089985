const React = require('react');
const Layout = require('./src/components/sections/Layout/Layout.tsx').default;

exports.onClientEntry = () => {
  const hubspot = document.createElement('script');
  hubspot.id = 'hubSpotChat';
  hubspot.src = '//js.hsforms.net/forms/v2.js';
  document.body.appendChild(hubspot);
};

exports.wrapPageElement = ({element, props}) => {
  const jquery = document.createElement('script');
  jquery.src = 'https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js';

  const scriptCaliberBody = document.createElement('script');
  scriptCaliberBody.src = 'https://cdn.calibermind.com/js/identifyEmail.latest.js';
  
  const pageUseForm = props?.pageContext?.useForm;
  if (props?.data?.scripts?.publicURL) {
    const scriptCaliberHead = document.createElement('script');
    scriptCaliberHead.src = props.data.scripts.publicURL;
    document.head.appendChild(scriptCaliberHead);
  } 

  if(pageUseForm) {
    document.body.appendChild(jquery);
    document.body.appendChild(scriptCaliberBody);
  }

  return <Layout {...props}>{element}</Layout>
};

exports.onRouteUpdate = ({location, prevLocation}) => {
  if(location?.state)
    location.state.prevPath = prevLocation?.pathname;
};