import { IGatsbyImageData } from "gatsby-plugin-image";

type ArticleInfoProps = {
  heroImage: {
    title: string,
    gatsbyImageData?: IGatsbyImageData,
  },
  country: string,
  title: string,
};

type GetArticleInfoFn = (
  site: {
    title: string,
    location: {
      location: string,
    },
    image: {
      title: string,
      gatsbyImageData?: IGatsbyImageData,
    },
  },
) => ArticleInfoProps;

// eslint-disable-next-line import/prefer-default-export
export const getArticleInfo: GetArticleInfoFn = (
  site,
) => ({
  country: site.title,
  title: site.location.location,
  heroImage: site.image,
});
