type ContentfulItemProps = {
  contentful_id: string,
  title: string,
  slug: string,
  publishDate: string,
};

type ArticleProps = {
  contentful_id: string,
  title: string,
  slug: string,
  type?: string,
  publishDate: string,
  categories: Array<ContentfulItemProps>,
};

type CategoryProps = {
  contentful_id: string,
  title: string,
  slug: string,
  type?: string,
  displayName?: string
  articlesList?: Array<ArticleProps>,
};

type GetDropDownListfrCategoriesFn = (
  categoriesList: CategoryProps[],
  setSlugs: boolean,
) => CategoryProps[];

// eslint-disable-next-line import/prefer-default-export
export const getDropDownListfrCategories: GetDropDownListfrCategoriesFn = (
  categoriesList,
  setSlugs = false,
)=>{
  const dropDownList = categoriesList
    ?.map(oneCategory => {
      const { 
        displayName, 
        slug: subSlug, 
        type 
      } = oneCategory;

      let moreArticlesSlug;
      if(type === "Blog")
        moreArticlesSlug = "blog";
      else if(type === "News") 
        moreArticlesSlug = "media";

      if(subSlug) 
        moreArticlesSlug += `/${subSlug}`;

      return ({
        ...oneCategory,
        title: displayName,
        slug: setSlugs && moreArticlesSlug,
        moreArticlesSlug,
      });
    });
  return dropDownList;
};
