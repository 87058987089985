
type JoinItemsFn = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  listItems: any[], 
  qtyByGroup: number, 
  qtyFirst: number,
) => any[][];

// eslint-disable-next-line import/prefer-default-export
export const joinItems: JoinItemsFn = (
  listItems,
  qtyByGroup,
  qtyFirst,
) => {
  const groups = [[]];
  let count = 0;
  for (let i = 0; i < listItems.length; i+=1) {
    const oneItem = listItems[i];
    if(
      qtyFirst && 
      groups.length <= 1 && 
      groups[0].length < qtyFirst
    ){
      groups[count].push(oneItem);
    }else if( 
      ( qtyFirst ? ( groups.length > 1 ) : (true) ) && 
      (groups[count].length < qtyByGroup) 
    ){
      groups[count].push(oneItem);
    }else{
      count += 1;
      groups.push([oneItem]);
    }
  }
  return groups;
};
