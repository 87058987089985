import React from "react";

import { ContentfulLivePreviewProvider } from "@contentful/live-preview/react";
import SEO from "@sections/SEO";

import "../../../styles/global.css";
import "@fontsource/atkinson-hyperlegible/400.css";
import "@fontsource/atkinson-hyperlegible/700.css";
import NewHeader from "@src/components/frame/NewHeader";
import NewFooter from "@src/components/frame/NewFooter";
import type { ContentfulNavigationNavMenu } from "@src/graphql/modules/navigation";

type PageProps = {
  __typename: string;
  contentful_id: string;
  title: string;
  slug: string;
};

type ItemProps = {
  childPages?: Array<PageProps>;
} & PageProps;

type SocialMediaProps = {
  __typename: string;
  contentful_id: string;
  linkedinLink: string;
  instagramLink: string;
  facebookLink: string;
  twitterLink: string;
};

type LayoutProps = {
  children?: React.ReactNode;
  data: {
    layout?: {
      menuItems: ItemProps[];
      privacyFooterItems: ItemProps[];
      requestDemoLabel: ItemProps;
      requestDemoLinkInternal: PageProps;
      socialMedia: SocialMediaProps;
      contactInfo: {
        raw: string;
      };
    };
    seo: PageProps;
    navigation: ContentfulNavigationNavMenu;
  };
};

const enablePreview = (process.env.GATSBY_CONTENTFUL_HOST ?? "").startsWith(
  "preview"
);

const Layout: React.FC<LayoutProps> = ({
  children,
  data: { seo: data, navigation },
}) => (
  <>
    <SEO data={data} />
    <NewHeader navMenu={navigation} />
    <ContentfulLivePreviewProvider
      enableInspectorMode={enablePreview}
      enableLiveUpdates={enablePreview}
      locale="en"
    >
      <main>{children}</main>
    </ContentfulLivePreviewProvider>

    <NewFooter navMenu={navigation} />
  </>
);

export default Layout;
