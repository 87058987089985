
type CaseStudyNodeProp = {
  contentful_id: string;
  title: string;
  slug?: string;
  topSection: (object & any)[];
  bannerImage?;
};

type CaseStudyProp = {
  node: CaseStudyNodeProp;
};

type CaseStudiesProp = {
  edges: CaseStudyProp[],
};

type CaseStudyConvertProp = {
  id: string;
  title: string;
  slug?: string;
  alt?: string;
  useFilter?: boolean;
  content?: string;
  image?;
}

type GetCaseStudiesListFn = (
  caseStudies: CaseStudiesProp,
) => CaseStudyConvertProp[];

// eslint-disable-next-line import/prefer-default-export
export const getCaseStudiesList: GetCaseStudiesListFn = (caseStudies) => {
  const caseStudiesListSplitted = [];
  const allCaseStudies = caseStudies.edges.map(({ node }) => ({
    id: node.contentful_id,
    title: node?.title,
    slug: node.slug,
    alt: node?.title,
    image: node?.bannerImage?.gatsbyImageData,
    useFilter: true,
    content: '',
  }));

  while(allCaseStudies.length) {
    caseStudiesListSplitted.push(allCaseStudies.splice(0,5));
  }

  return caseStudiesListSplitted;
}
