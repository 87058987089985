type IsIframeFn = (
  element: string | {
    content: Array<{
      value: string,
    }>
  }
) => boolean;

// eslint-disable-next-line import/prefer-default-export
export const isIframe: IsIframeFn = (element) => {
  let checkIframe = false;

  if(typeof element == 'string'){
    checkIframe = element?.match(/iframe/g)?.length === 2;
  }else if(typeof element == 'object' && element?.content ){
    checkIframe = element.content.filter( el => (
      el?.value?.match(/iframe/g)?.length === 2
    )).length > 0;
  }

  return checkIframe;
};
