import React from "react";

import { Link } from "@elements/Link";
import type { ContentfulNavigationNavMenu } from "@src/graphql/modules/navigation";
import LinkedInSvg from "@svg/icons/linkedin.svg";
import YouTubeSvg from "@svg/icons/youtube.svg";
import LogoDarkSvg from "@svg/logos/reptrak-black.svg";

const ContactsColumn = () => (
  <div>
    <p className="mb-4 uppercase text-rt-s text-grey-400">Contacts</p>
    <hr className="mb-4 max-w-[36px] border-green-10 border-t-[2px]" />
    <div className="flex flex-col gap-4">
      <a href="tel:+1 617 758 0955" className="hover:underline">
        +1 617 758 0955
      </a>
      <a
        href="mailto:info@reptrak.com"
        className="hover:underline"
      >
        info@reptrak.com
      </a>
    </div>
  </div>
);

const MenuItems = ({ navMenu, ignoreLabel = false }) => {
  if (!navMenu?.items?.at(0)) {
    return null
  }

  return navMenu?.items?.map((item, idx) => {
    if (item.__typename === "ContentfulNavigationNavMenu") {
      return (
        <div key={item.contentful_id}>
          {!ignoreLabel && item.label && (
            <>
              <p className="mb-4 text-grey-400 uppercase text-rt-s">
                {item.label}
              </p>
              <hr className="mb-4 max-w-[36px] border-green-10 border-t-[2px]" />
            </>
          )}
          <div className="flex flex-col gap-4">
            {item.items?.map((subItem) => {
              if (subItem.__typename === "ContentfulNavigationNavItem") {
                return (
                  <Link
                    key={subItem.contentful_id}
                    to={subItem.page?.slug ? `/${subItem.page.slug}` : subItem.url}
                    className="hover:underline"
                  >
                    {subItem.label}
                  </Link>
                );
              }
              if (subItem.__typename === "ContentfulNavigationNavMenu") {
                return (
                  <MenuItems
                    key={subItem.contentful_id}
                    navMenu={{ items: [subItem] }}
                    ignoreLabel={true}
                  />
                );
              }
            })}
          </div>
        </div>
      );
    }
    return null;
  })
};

const NewFooter = ({
  navMenu,
}: {
  navMenu: ContentfulNavigationNavMenu;
}): JSX.Element => (
  <footer className="inner py-12 md:py-16 font-atkinson !px-8">
    <div className="pb-12 grid md:grid-cols-1-2 gap-12 md:gap-16">
      <div className="flex flex-col gap-4">
        <Link aria-current="page" aria-label="logo-reptrak" to="/">
          <LogoDarkSvg className="max-w-[144px] w-40 h-20" />
        </Link>
        <div className="flex gap-4">
          <a
            href="https://www.linkedin.com/company/reptrak/"
            target="_blank"
            className="hover:opacity-60 focus:opacity-60 transition-opacity duration-250"
          >
            <LinkedInSvg width="32" height="24" />
          </a>
          <a
            href="https://www.youtube.com/c/TheRepTrakCompany"
            target="_blank"
            className="hover:opacity-60 focus:opacity-60 transition-opacity duration-250"
          >
            <YouTubeSvg width="32" height="24" />
          </a>
        </div>
        <p className="text-grey-500 mt-4">
          RepTrak provides expert strategies and precise metrics to effectively
          shape and measure your brand's reputation, guiding you in changing
          hearts and minds with quantifiable results.
        </p>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-12 md:gap-5">
        <MenuItems navMenu={navMenu} />
        <ContactsColumn />
      </div>
    </div>
    <div className="flex items-center justify-center pt-8 border-t border-grey-200 text-grey-400">
      <p className="text-center">
        Boston Global HQ - 31 Milk Street, #960969 - Boston, MA 02196.{" "}
        <span className="block md:inline-block md:ml-10">
          Copyright © Reptrak, {new Date().getFullYear()}
        </span>
      </p>
    </div>
  </footer>
);

export default NewFooter;
