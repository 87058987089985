
type RankingProps = {
  contentful_id: string;
  slug: string;
  year: string;
};

type DropdownProps = {
  contentful_id: string;
  title: string;
  slug: string;
};

type GetDropDownListfrRankingsFn = (
  rankings: RankingProps[],
  activeRanking: RankingProps,
  mainSlug: string,
  selectYear: number,
) => DropdownProps[];

// eslint-disable-next-line import/prefer-default-export
export const getDropDownListfrRankings: GetDropDownListfrRankingsFn = (
  rankings,
  activeRanking,
  mainSlug,
  selectYear,
)=>{
  const dropDownList = rankings
    .filter((oneRanking) => Number(oneRanking.year) !== selectYear )
    .map( oneRanking => {
      let slug = mainSlug;
      if(activeRanking.contentful_id !== oneRanking.contentful_id)
        slug += `/${oneRanking.slug}`;
      
      return ({
        contentful_id: oneRanking.contentful_id,
        title: `${oneRanking.year} Report`,
        slug,
      });
    });
  return dropDownList;
};
