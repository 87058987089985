import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import RichText from '@elements/RichText';
import React from 'react';

import './style.css';

const ArticleWrappedBody = ({children}) => {
  let pictureSize = ''
  let pictureAlignment = ''

  const { title,
          body,
          mediaAligment, 
          mediaResize, 
          urlVideoYoutube,
          showVideo,
          showImage } = children;

  mediaAligment === 'left' ? pictureAlignment = 'wrapper-float-left' : pictureAlignment = 'wrapper-float-left'
  
  if (showImage) {
    const imageAllSizes = children.image?.gatsbyImageData?.images?.sources[0].srcSet
    const imageSets = imageAllSizes?.split('\n')
  
    const protoTS = imageSets[0].split(' ')
    const thirdSize = protoTS[0]
  
    const protoMS = imageSets[1].split(' ')
    const middleSize = protoMS[0]
  
    const protoOriginal = imageSets[2].split(' ')
    const original = protoOriginal[0]
  
    mediaResize === 'original' ? pictureSize = original : 
    mediaResize === 'middle-size' ? pictureSize = middleSize :
    mediaResize === 'third-size' ? pictureSize = thirdSize : null
    
    return (
      <>
        <div className="wrapper-text">
          
          <img 
            alt={children.image.title} 
            src={pictureSize} 
            className={pictureAlignment}
          />
            <span className="wrapper-title">{title} </span>
            <div className="article__body-inner">
              {
                body && <RichText bodyRichText={body} /> 
              }
            </div>
        </div>
      </>
    );
  } else if (showVideo) {
    return (
      <>
        <div className="wrapper-text">
          <iframe 
            src={urlVideoYoutube}
            title="YouTube video player" 
            frameBorder="0" 
            allow="accelerometer; 
                   autoplay; 
                   clipboard-write; 
                   encrypted-media; 
                   gyroscope; 
                   picture-in-picture" 
            allowFullScreen
            className={pictureAlignment}
            >
          </iframe>
          <span className="wrapper-title">{title} </span>
          <div className="article__body-inner">
            {
              body && <RichText bodyRichText={body} />
            }
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="wrapper-text">
          <span className="wrapper-title">{title} </span>
          <div className="article__body-inner">
            {
              body && <RichText bodyRichText={body} />
            }
          </div>
        </div>
      </>
    );
  }
};
export default ArticleWrappedBody
