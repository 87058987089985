
type GetPageFrLocationFn = (
  location: {
    search: string
  },
) => number;

// eslint-disable-next-line import/prefer-default-export
export const getNumberPageFrLocation: GetPageFrLocationFn = (
  location,
) => {
  const params = new URLSearchParams(location.search);
  const page = Number(params.get("page"));
  return page > 1 ? page : 1;
};
