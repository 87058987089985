exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-case-studies-tsx": () => import("./../../../src/pages/case-studies.tsx" /* webpackChunkName: "component---src-pages-case-studies-tsx" */),
  "component---src-pages-contentful-landing-page-slug-tsx": () => import("./../../../src/pages/{contentfulLandingPage.slug}.tsx" /* webpackChunkName: "component---src-pages-contentful-landing-page-slug-tsx" */),
  "component---src-templates-article-overview-tsx": () => import("./../../../src/templates/articleOverview.tsx" /* webpackChunkName: "component---src-templates-article-overview-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-careers-page-tsx": () => import("./../../../src/templates/careersPage.tsx" /* webpackChunkName: "component---src-templates-careers-page-tsx" */),
  "component---src-templates-case-study-tsx": () => import("./../../../src/templates/caseStudy.tsx" /* webpackChunkName: "component---src-templates-case-study-tsx" */),
  "component---src-templates-company-tsx": () => import("./../../../src/templates/company.tsx" /* webpackChunkName: "component---src-templates-company-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-person-tsx": () => import("./../../../src/templates/person.tsx" /* webpackChunkName: "component---src-templates-person-tsx" */),
  "component---src-templates-ranking-list-tsx": () => import("./../../../src/templates/rankingList.tsx" /* webpackChunkName: "component---src-templates-ranking-list-tsx" */)
}

