const plugin = require('tailwindcss/plugin');

module.exports = {
  content: ['./src/**/*.tsx', './src/**/*.jsx', './src/**/theme.ts'],
  mode: process.env.NODE_ENV === 'development' && 'jit',
  theme: {
    fontFamily: {
      atkinson: ['Atkinson Hyperlegible'],
      sans: ['Open Sans, sans-serif'],
      serif: ['Roboto Slab, serif'],
      mont: ['Montserrat, serif'],
    },
    minWidth: {
      0: '0',
      '1/4': '25%',
      '1/2': '50%',
      '3/4': '75%',
      full: '100%',
      '20r': '20rem',
      '20px': '20px',
      '200px': '200px',
    },
    screens: {
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1280px',
    },
    textColor: (theme) => ({
        ...theme('colors'),
        'rt-green': '#05D477',
      }),
    boxShadow: {
      menuDropdown: '0px 24px 32px 4px rgba(167, 174, 186, 0.12)',
      zapnito: '0 1px 4px 0 rgba(32,34,38,.15)',
      default: '0px 2.221px 11.105px 0px rgba(21, 27, 38, 0.10)',
    },
    extend: {
      colors: {
        'rt-anchor-blue': '#45729D',
        'rt-green': '#05D477',
        'rt-green-light': '#06E07E',
        'rt-green-dark': '#03703F',
        'rt-grey-smoke': '#ABB7C2',
        'rt-pale-sky': '#657687',
        'rt-snow-white': '#FFFFFF',
        'rt-steely-grey': '#657687',
        'rt-white-smoke': '#F4F4F4',
        'rt-white-ice': '#DAFEEE',
        'rt-smoke-lighter': 'rgba(0, 0, 0, 0.75)',
        'rt-rating-excellent': '#386336',
        'rt-rating-strong': '#05D477',
        'rt-rating-average': '#F0A935',
        'rt-rating-weak': '#F0592E',
        'rt-rating-poor': '#9E1B21',
        'rt-purple': '#5867FF',
        'rt-purple-rgb': 'rgb(88 103 255 / 15%)',
        'rt-white': '#FFFFFF',
        'rt-grey': '#ECECEC',
        'rt-purple-light': '#E1E4FF',
        'rt-dark-900': '#212121',
        'rt-medium-gray': '#3b4759',
        'rt-light-gray': '#f4f6fb',
        'rt-dark-green': '#1b3a25',
        'rt-pink': '#ee4056',
        'grey-smooth': '#FAFBFD',
        'grey-desc': '#677489',
        'info-5': '#FFDC96',
        'amber': '#FFF9ED',
        'grey-200': '#E5E7EB',
        'grey-400': '#9CA3AF',
        'grey-500': '#6B7280',
        'new-green-lightest': '#30A46C',
        'neutral-2': '#F9F9FB',
        'neutral-3': '#F2F2F5',
        'neutral-6-alpha': 'rgba(1, 1, 46, 0.13)',
        'neutral-7': '#D3D4DB',
        'neutral-7-alpha': 'rgba(1, 6, 47, 0.17)',
        'neutral-8': '#B9BBC6',
        'neutral-10': '#7E808A',
        'neutral-10-alpha': 'rgba(0, 4, 24, 0.51)',
        'neutral-11': '#60646C',
        'neutral-12': '#1C2024',
        'accent-2': '#F2FCF5',
        'accent-4': '#143125',
        'accent-5-alpha': 'rgba(17, 254, 147, 0.15)',
        'accent-7': '#1F5E41',
        'accent-8': '#05D477',
        'accent-8-alpha': 'rgba(0, 147, 77, 0.64)',
        'accent-9-alpha': 'rgba(0, 143, 74, 0.81)',
        'accent-10': '#127F4A',
        'accent-10-alpha': 'rgba(0, 131, 71, 0.84)',
        'accent-11': '#0E6A3E',
        'accent-11-alpha': 'rgba(0, 107, 59, 0.91)',
        'accent-12': '#193B2D',
        'new-green-light': '#127F4A',
        'new-green': '#0E6A3E',
        'new-green-dark': '#193B2D',
        'new-green-darker': '#194633',
        'new-black': '#1C2024',
        'black-andel': '#1E1E1E',
        'overlay': 'rgba(0, 0, 0, 0.25)',
        'overlay-dark': 'rgba(0, 0, 0, 0.4)',
        'overlay-grey': 'rgba(213, 234, 254, 0.24)',
        'overlay-green': 'rgba(17, 254, 147, 0.15)',
        'light-green': '#F2FCF5',
        'middle-green': '#0E6A3E',
        'dark-green': '#173A2A',
        'light-blue': '#E6EEFF',
        'middle-blue': '#262A89',
        'dark-blue': '#161650',
        'light-amber': '#FFF9ED',
        // Color scales from figma
        // Amber (Info)
        'amber-1': '#FEFDFB',
        'amber-2': '#FFF9ED',
        'amber-3': '#FFF3D0',
        'amber-4': '#FFECB7',
        'amber-5': '#FFDC96',
        'amber-6': '#F5D08C',
        'amber-7': '#E4BB78',
        'amber-8': '#D6A35C',
        'amber-9': '#FFC53D',
        'amber-10': '#FFBA1A',
        'amber-11': '#915930',
        'amber-12': '#4F3422',
        // Green (Accent)
        'green-1': '#FBFEFC',
        'green-2': '#F2FCF5',
        'green-3': '#E9F9EE',
        'green-4': '#DDF3E4',
        'green-5': '#CCEBD7',
        'green-6': '#A6F6D0',
        'green-7': '#5AE9A6',
        'green-7.5': '#11fe93',
        'green-8': '#05D477',
        'green-9': '#1AA963',
        'green-10': '#127F4A',
        'green-11': '#0E6A3E',
        'green-12': '#193B2D',
        'green-13': '#173A2A',
        // Blue (Brand)
        'blue-1': '#F8FAFF',
        'blue-2': '#E6EEFF',
        'blue-3': '#CFDCFD',
        'blue-4': '#AFC5FF',
        'blue-5': '#94B8FF',
        'blue-6': '#758BFF',
        'blue-7': '#5867FF',
        'blue-8': '#3638F5',
        'blue-9': '#3638F5',
        'blue-10': '#2526AE',
        'blue-11': '#262A89',
        'blue-12': '#161650',
      },
      fontSize: {
        inherit: 'inherit',
        xxs: '0.675rem',
        base: '1rem',
        '17px': '17px',
        '18px': '18px',
        '19px': '19px',
        '21px': '21px',
        '22px': '22px',
        '1.5xl': '1.3125rem',
        '2.5xl': '1.6875rem',
        '3.5xl': '2rem',
        '3.6xl': ['2.25rem', { lineHeight: '60px' }],
        '4.5xl': ['2.75rem', { lineHeight: '4rem' }],
        '5xl': ['3rem', { lineHeight: '4rem' }],
        '8xl': '5rem',
        '9xl': '6rem',
        '10xl': '7rem',
        '12xl': '9rem',
        'h1': ['3.75rem', {lineHeight: '1' }],
        'rt-xs': '0.75rem',
        'rt-s': '0.875rem',
        'rt-m': '1.5rem',
        'rt-2m': '1.75rem',
        'rt-l': '2.2rem',
        'rt-2l': '2.625rem',
        'rt-xl': '3.75rem',
      },
      height: {
        inherit: 'inherit',
        big: '40vw',
        small: '20vw',
        '4px': '4px',
        '11px': '11px',
        '24px': '24px',
        '32px': '32px',
        '40px': '40px',
        '48px': '48px',
        '64px': '64px',
        '180px': '180px',
        '320px': '320px',
        '480px': '480px',
        'full-woHeader': 'calc(100% - 80px)',
      },
      fontWeight: {
        med: '500',
        medium: '600',
        large: '900',
      },
      spacing: {
        '1px': '1px',
        '11px': '11px',
        104: '26rem',
        108: '27rem',
        125: '31.25rem',
        136: '34rem',
        160: '40rem',
        176: '44rem',
        192: '48rem',
        '13px': '13px',
        '14px': '14px',
        '22px': '22px',
        20: '80px',
        30: '120px',
        32: '128px',
        34: '144px',
        section: '3rem',
      },
      screens: {
        xs: { raw: '(min-width: 375px)' },
        'only-mobile': { raw: '(max-width: 767px)' },
        'only-mobile-medium': { raw: '(min-width: 480px) and (max-width: 639px)'  },
        'only-ipad': { raw: '(min-width: 767px) and (max-width: 1024px)' },
        'medium-desktop': { raw: '(min-width: 1280px)' },
        'large-desktop': { raw: '(min-width: 1400px)' },
        'extra-desktop': { raw: '(min-width: 1536px)' },
        '150-desktop': { raw: '(min-width: 1024px) and (-webkit-device-pixel-ratio: 1.5)' },
        '150-medium-desktop': { raw: '(min-width: 1280px) and (-webkit-device-pixel-ratio: 1.5)' },
        '150-large-desktop': { raw: '(min-width: 1400px) and (-webkit-device-pixel-ratio: 1.5)' },
      },
      borderRadius: {
        '2.5xl': '1.25rem',
        '3.5xl': '1.75rem',
        '4xl': '2.5rem',
        '4.5xl': '3.5rem',
        '5xl': '4.5rem',
      },
      minHeight: {
        px: '1px',
        13: '3.375rem',
        14: '3.5rem',
        96: '24rem',
        104: '26rem',
        '480px': '480px',
      },
      maxHeight: {
        'full-woHeader': 'calc(100% - 80px)',
        '320px': '320px',
      },
      lineHeight: {
        12: '3rem',
        13: '3.375rem',
        16: '4rem',
        20: '5rem',
      },
      width: {
        unset: 'unset',
        '11px': '11px',
        '24px': '24px',
        '32px': '32px',
        '44px': '44px',
        '48px': '48px',
        '60px': '60px',
        '64px': '64px',
        '80px': '80px',
        '155px': '155px',
        '200px': '200px',
        '400px': '400px',
      },
      maxWidth: {
        '1.5xl': '38rem',
        '350px': '350px',
        '760px': '760px',
      },
      gridTemplateColumns: {
        '1-2': '1fr 2fr',
      },
      backgroundImage: {
        'main-gradient': "linear-gradient(166deg, #FFF 10.1%, #BFE6C3 100.17%);",
        'arrowDownWhite': `url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none"><rect width="18" height="18" fill="white" fill-opacity="0.01"/><path fill-rule="evenodd" clip-rule="evenodd" d="M3.76189 7.38983C3.98853 7.14808 4.36823 7.13584 4.60997 7.36247L8.99961 11.4778L13.3892 7.36247C13.631 7.13584 14.0107 7.14808 14.2374 7.38983C14.4639 7.63159 14.4517 8.01129 14.21 8.23792L9.40997 12.738C9.17918 12.9543 8.82004 12.9543 8.58925 12.738L3.78925 8.23792C3.5475 8.01129 3.53526 7.63159 3.76189 7.38983Z" fill="%23EDEEF0"/></svg>')`,
      },
      backgroundPosition: {
        'right-3': 'center right 12px',
      },
      flex: {
        '25%': '0 0 25%',
        '20%': '0 0 20%',
        '33%': '0 0 33.33%',
        '50%': '0 0 50%',
        '66%': '0 0 66.66%',
      },
      keyframes: {
        fadeIn: {
          '0%': { opacity: '0' },
          '100%': { opacity: '1' },
        }
      },
      animation: {
        'fadeIn': 'fadeIn 300ms forwards',
      },
      rotate: {
        '45': '45deg',
        '180': '180deg',
      },
      translate: {
        '50': '50%',
      },
      gap: {
        '2': '8px',
        '4': '16px',
        '22': '88px',
      }
    },
  },
  variants: {
    extend: {
      textColor: ['disabled'],
    },
  },
  plugins: [
    plugin(({ addComponents }) => {
      const mediaQueries = [
        {
          [`@media (min-width: 1024px) and (-webkit-device-pixel-ratio: 1.5)`]: {
            '.container': {
              'max-width': '80%',
            },
          },
        },
        {
          [`@media (min-width: 1600px) and (-webkit-device-pixel-ratio: 1.5)`]: {
            '.container': {
              'max-width': '1280px',
            },
          },
        },
      ];
      addComponents(mediaQueries);
    }),
  ],
  prefix: '',
  important: false,
};
