
type ChangeUrlPathFn = (
  isQuery: boolean,
  content: string,
) => string;

// eslint-disable-next-line import/prefer-default-export
export const changeUrlPath: ChangeUrlPathFn = (
  useQuery, 
  content
) => useQuery ? `?page=${content}` : `${content}/`;
