type ArticleProps = {
  __typename: string;
  contentful_id: string;
  title: string,
};

type GetRelatedArticlesFn = (
  relatedArticles: ArticleProps[], 
  latestArticles: ArticleProps[], 
  qtyElements: number,
) => ArticleProps[];

type AddArticleFn = (
  currentListArticles: ArticleProps[], 
  listArticlestoAdd: ArticleProps[], 
  qtyElements: number,
) => ArticleProps[];

const addArticles: AddArticleFn = (currentListArticles, listArticlestoAdd, qtyElements) => {
  const newListArticle = [ ...currentListArticles ];

  for (let i = 0; i < listArticlestoAdd?.length; i+=1) {
    if(qtyElements > newListArticle.length){
      const found = newListArticle.find(
        ({ contentful_id }) => contentful_id === listArticlestoAdd[i].contentful_id 
      );
      if(!found) newListArticle.push(listArticlestoAdd[i]);
    }
  }

  return newListArticle;
}

// eslint-disable-next-line import/prefer-default-export
export const getRelatedArticles: GetRelatedArticlesFn = (
  relatedArticles,
  latestArticles,
  qtyElements,
) => {
  let listArticles = [];

  if(relatedArticles?.length >= qtyElements){
    listArticles = [ ...relatedArticles ];
  }else{

    if(latestArticles){
      listArticles.push(latestArticles[0]);
    }
    listArticles = addArticles(listArticles, relatedArticles, qtyElements);
    listArticles = addArticles(listArticles, latestArticles, qtyElements);
  }

  return listArticles;
};
