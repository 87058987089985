
type GetReputationScoreDefinitionFn = (
  score: number,
) => string;

// eslint-disable-next-line import/prefer-default-export
export const getReputationScoreDefinition: GetReputationScoreDefinitionFn = (
  score,
) => {
  let label = '';
  if (score >= 80)      label+= 'Refers to a company that has achieved a reputation score within the 80–100-point range on a 0 – 100 scale.  This is the highest tier in our normative scale and is shown in dark green.';
  else if (score >= 70) label+= 'Refers to a company that has achieved a reputation score within the 70–79-point range on a 0 – 100 scale.  This is the second highest tier in our normative scale and is shown in light green.';
  else if (score >= 60) label+= 'Refers to a company that has achieved a reputation score within the 60–69-point range on a 0 – 100 scale.  This is the middle tier in our normative scale and is shown in yellow.';
  else if (score >= 40) label+= 'Refers to a company that has achieved a reputation score within the 40–59-point range on a 0 – 100 scale.  This is the second lowest tier in our normative scale and is shown in orange. ';
  else if (score >= 0)  label+= 'Refers to a company that has achieved a reputation score within the 0–39-point range on a 0 – 100 scale.  This is the lowest tier in our normative scale and is shown in red. ';
  
  return label;
};
