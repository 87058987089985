import React from 'react';
import { Helmet } from 'react-helmet';

import { useStaticQuery, graphql } from 'gatsby';

type ImageProps = {
  title: string,
  resize: {
    src: string,
    height: string,
    width: string,
  },
};

type RankingListProps = {
  __typename: string,
  contentful_id: string,
  slug: string,
  rankingImage: ImageProps,
  activeRanking: {
    contentful_id: string,
  }
};

type SEOProps = {
  data: {
    __typename: string,
    contentful_id: string,
    title: string,
    slug?: string,
    type?: string,
    description: {
      description?: string,
    },
    publishDate?: string,
    updatedDate?: string,
    image?: ImageProps,
    hero?: {
      image?: ImageProps
    },
    shortDescription?: string,
    ranking_list: RankingListProps[],
  },
};

const SEO = ({
  data,
}: SEOProps): JSX.Element => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          siteUrl
        }
      }
    }
  `);

  let url = "";
  let type = "website";
  let imageUrl = data?.image?.resize?.src;
  let description = data?.description?.description || site.siteMetadata.description;
  let title = `${ data?.title ? `${data.title} | ` : '' }${site.siteMetadata.title}`;
  let schemaObject;
  const publishDate = data?.publishDate;
  const updatedDate = data?.updatedDate;
  const optimizeUrl = data?.slug;

  // eslint-disable-next-line no-underscore-dangle
  switch (data?.__typename) {
    case 'ContentfulPage':
      if(data.slug === 'homepage')
        url = site.siteMetadata.siteUrl;
      else
        url = `${site.siteMetadata.siteUrl}/${data.slug}/`;
      break;
    case 'ContentfulArticleOverview':
      url = `${site.siteMetadata.siteUrl}/${data.slug}/`;
      break;
    case 'ContentfulArticle':
      url = `${site.siteMetadata.siteUrl}/${ data.type.toLowerCase() }/${data.slug}/`;
      type = data?.type ? "article" : "website";
      break;
    case 'ContentfulPerson':
      url = `${site.siteMetadata.siteUrl}/${data.slug}/`;
      description = data?.shortDescription;
      break;
    case 'ContentfulCareersPage':
      url = `${site.siteMetadata.siteUrl}/${data.slug}/`;
      imageUrl = data?.hero?.image.resize.src;
      break;
    case 'ContentfulRanking': {
      const rankList = data?.ranking_list[0];
      imageUrl = rankList.rankingImage.resize.src;
      if(rankList?.activeRanking.contentful_id === data.contentful_id)
        url = `${site.siteMetadata.siteUrl}/${rankList?.slug}/`;
      else
        url = `${site.siteMetadata.siteUrl}/${rankList?.slug}/${data.slug}`;
      break;
    }
    case 'ContentfulCompany':
      title = `Reptrak | ${data.title} rating`;
      description = `Reptrak's company page for ${data.title}, highlighting their Reptrak score and some company information`;
      schemaObject = JSON.stringify({
        '@context': 'http://schema.org/',
        '@type': 'Organization',
        name: data.title,
        description,
        logo: data?.image?.resize.src,
        url: `company/${data.slug}`,
      });
      break;
    default: break;
  }

  const height = data?.image?.resize?.height; 
  const width = data?.image?.resize?.width;

  return (
    <Helmet>
      <html lang="en" />
      <title>{title}</title>
      <meta content={title} name="title" />
      <meta content={description} name="description" />
      {
        optimizeUrl?.includes('request-demo')
        ?
          <script src="https://www.googleoptimize.com/optimize.js?id=OPT-K9BW6SB"></script>
        :
        null
      }
      {
        imageUrl && <meta content={imageUrl} name="image"  />
      }

      {/* OpenGraph */}
      <meta content={title} property="og:title" />
      <meta content={description} property="og:description" />
      {
        imageUrl && <meta content={imageUrl} property="og:image" />
      }
      {
        height && <meta content={height} property="og:image:height" />
      }
      {
        width && <meta content={width} property="og:image:width" />
      }

      <meta content={url} property="og:url" />
      <meta content={type} property="og:type" />
      {
        publishDate && <meta content={publishDate} property="article:published_time" />
      }
      {
        updatedDate && <meta content={updatedDate} property="article:published_time" />
      }
      
      {/* Twitter */}
      <meta content={title} name="twitter:title" />
      <meta content={description} name="twitter:description" />
      <meta content={ imageUrl ? 'summary_large_image' : 'summary' } name="twitter:card" />
      {
        imageUrl && <meta content={imageUrl} name="twitter:image" />
      }
      {
        schemaObject && <script type="application/ld+json">{schemaObject}</script>
      }
    </Helmet>
  );
};

export default SEO;
