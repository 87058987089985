import { HTMLProps } from 'react';

type InputProps = HTMLProps<HTMLAllCollection> & { 
  labels: { 
    textContent: string,
  }, 
  value: string,
};

type FormRefProps = {
  current: {
    el: {
      firstChild: InputProps[],
    },
  },
};

// eslint-disable-next-line import/prefer-default-export
export const getClientEmail = ( formReference: FormRefProps ): string => {
  const formB: InputProps[] = Array.from(formReference.current.el.firstChild);
  const email = formB.find( inp => inp.className.match('hs-input') && inp.type.match('email') );
  return email.value;
};
