type GetParamValueFn = (
  code: string,
  paramName: string,
) => string;

// eslint-disable-next-line import/prefer-default-export
export const getParamValue: GetParamValueFn = (code = '', paramName = '') => {
  let found = '';

  if(paramName.length > 0 && code.search(paramName) >= 0){
    const searchA = code.search(paramName) + paramName.length;
    const sliceCode = code.substr( searchA );
    const main = sliceCode.search('"') + 1;
    const final = sliceCode.substr( main ).search('"');
    found = sliceCode.slice( main, main + final );
  }

  return found;
};
