import { useEffect, useState } from 'react';
import { theme } from '../../tailwind.config';

type IsSmScreenSizeFn = (
  clientWidth: number
) => boolean;

const getSizeNumber = (sizeText: string): number => {
  const newSizeText = sizeText.replace('px','');
  return Number(newSizeText);
};

// eslint-disable-next-line import/prefer-default-export
export const isSmScreenSize: IsSmScreenSizeFn = (
  clientWidth = 0
) => clientWidth < getSizeNumber(theme.screens.sm);

// Default height and width so that
// we can use this hook in SSR with backwards compatibility
// for undefined window object b/c
// undefined < 768 is false.
export const useScreenSize = (width?: number, height?:  number) => {
  const [screenSize, setScreenSize] = useState({
    width: width || 0,
    height: height || 0,
  });

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return screenSize;
};
