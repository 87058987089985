type CompanyIdList = number[];

type CompanyProps = {
  contentful_id: string,
  companyId: number,
  position: number,
  slug: string,
};

type ContentfulCompanyProps = {
  edges: Array<{
    node: CompanyProps,
  }>
};

type RankingProps = {
  contentful_id: string,
  displayName: string,
  description: {
    description: string
  },
  reportLink: {
    label: string,
    url: string,
  },
  companyRankings: CompanyProps[],
  year: number,
};

type GetCompaniesListFn = (
  page: number,
  companyList: CompanyIdList[],
  ranking: RankingProps,
  activeRanking: RankingProps,
  companies: ContentfulCompanyProps
) => CompanyProps[];

// eslint-disable-next-line import/prefer-default-export
export const getCompaniesList: GetCompaniesListFn = (
  page,
  companyList,
  ranking,
  activeRanking,
  companies
) => {
  let newPage = 0;
  if(page <= 1)
    newPage = 0;
  else if(companyList.length >= page)
    newPage = page - 1;
  else if(page > companyList.length)
    newPage = companyList.length - 1;

  return companies.edges
    .map(({ node }) => node)
    .filter( ({ companyId }) => (
      companyList?.[newPage]?.find(
        oneCompany => oneCompany === companyId
      )
    ))
    .map( company => {
      const found = ranking.companyRankings.find( 
        ({ companyId }) => companyId === company.companyId 
      );
      let { slug } = company;
      if(ranking.contentful_id !== activeRanking.contentful_id)
        slug += `/${ranking.year}`;

      return {
        ...company,
        ...found,
        slug,
      }
    })
    .sort((a,b) => a.position - b.position );
};
