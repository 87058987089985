import { theme } from '../../tailwind.config';

type ResizeItemsFn = (
  reference: React.MutableRefObject<any>,
  breakpoints: {
    sm?: number,
    md?: number,
    lg?: number,
    xl?: number,
    xxl?: number,
  }
) => void;

const getSizeNumber = (sizeText: string): number => {
  const newSizeText = sizeText.replace('px','');
  return Number(newSizeText);
};

// eslint-disable-next-line import/prefer-default-export
export const resizeItems: ResizeItemsFn = (
  reference,
  breakpoints,
) => {
  let max = 1;
  const containerElement = reference.current;
  const innerElement = containerElement?.firstChild;
  if(innerElement){
    if(breakpoints){
      Object.entries(breakpoints).forEach((breakpoint)=>{
        const [ size, quantity ] = breakpoint;
        const sizeNumber = getSizeNumber(theme.screens[size]);
        if(containerElement.clientWidth >= sizeNumber)
          max = quantity;
      });
    }
  
    const itemWidth = innerElement.clientWidth / max;
    const firstElement = innerElement.firstChild;
    const listItems = firstElement.children;
    for(let i = 0; i < listItems.length; i+=1)
      listItems[i].style.width = `${itemWidth}px`;
  
    firstElement.style.transition = 'none';
    firstElement.style.transform = `translateX(-${itemWidth}px)`;
  }
};
