
type RankingProps = {
  contentful_id: string;
  slug: string;
  year: string;
  companyRankings: Array<{
    companyId: string;
  }>
};

type CompanyProps = {
  companyId: string;
  slug: string;
};

type DropdownProps = {
  contentful_id: string;
  title: string;
  slug: string;
};

type GetDropDownListfrCompaniesFn = (
  rankings: RankingProps[],
  rankingSelectId: string,
  rankingActiveId: string,
  company: CompanyProps,
) => DropdownProps[];

// eslint-disable-next-line import/prefer-default-export
export const getDropDownListfrCompanies: GetDropDownListfrCompaniesFn = (
  rankings,
  rankingSelectId,
  rankingActiveId,
  company,
)=>{
  const dropDownList = rankings
    .filter(
      ({ contentful_id }) => contentful_id !== rankingSelectId
    )
    .filter(
      (oneRanking) => (
        oneRanking.companyRankings.find(
          ({ companyId }) => companyId === company.companyId
        )
      )
    )
    .map(oneRanking => {
      let slug = `rankings/company/${company.slug}`;
      if(oneRanking.contentful_id !== rankingActiveId)
        slug += `/${oneRanking.year}`;

      return {
        contentful_id: oneRanking.contentful_id,
        title: `${oneRanking.year} Report`,
        slug,
      }
    })
  return dropDownList;
};
