
type ContentfulItemProps = {
  contentful_id: string,
  title: string,
  slug: string,
  type: string,
  publishDate: string,
};

type GetLatestArticleFn = (
  allArticles: {
    edges: Array<{
      node: ContentfulItemProps,
    }>;
  },
  type: string,
) => ContentfulItemProps;

// eslint-disable-next-line import/prefer-default-export
export const getLatestArticle: GetLatestArticleFn = (allArticles, type) => {
  const latest = allArticles?.edges
    .map(({ node }) => node)
    .filter(
      article => article.type === type
    );
  return latest[0];
};
