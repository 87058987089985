type SocialMediaProps = {
  contentful_id: string,
  facebookLink: string,
  instagramLink: string,
  linkedInLink: string,
  twitterLink: string,
  youTubeLink: string,
};

type LinkType = {
  slug: string,
  social: string,
}

type SocialListProps = {
  links: LinkType[],
  id: string,
}

type GetSocialListFn = (
  socialMedia: SocialMediaProps,
  id: string,
) => boolean | SocialListProps;

// eslint-disable-next-line import/prefer-default-export
export const getSocialList: GetSocialListFn = (socialMedia) => {
  const links = [];
  
  if(socialMedia?.linkedInLink){
    links.push({
      slug: socialMedia?.linkedInLink,
      social: 'linkedIn',
    });
  }
  if(socialMedia?.instagramLink){
    links.push({
      slug: socialMedia.instagramLink,
      social: 'instagram',
    });
  }
  if(socialMedia?.facebookLink){
    links.push({
      slug: socialMedia?.facebookLink,
      social: 'facebook',
    });
  }
  if(socialMedia?.twitterLink){
    links.push({
      slug: socialMedia?.twitterLink,
      social: 'twitter',
    });
  }
  if(socialMedia?.youTubeLink){
    links.push({
      slug: socialMedia?.youTubeLink,
      social: 'youtube',
    });
  }

  if(links.length > 0)
    return { links, id: socialMedia?.contentful_id };

  return false;
};
