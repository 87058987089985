type SplitedListProps = [
  any[],
  any[],
]

type SplitListFn = (
  list: any[],
  qty: number,
) => SplitedListProps;

// eslint-disable-next-line import/prefer-default-export
export const splitList: SplitListFn = (list, qty) => [
  list.slice(0, qty),
  list.slice(qty)
];
