import React from 'react';

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import Button from '@elements/Button';
import ArticleWrappedBody from '@elements/ArticleWrappedBody';
import { getParamValue, isIframe, getClasses, useScreenSize } from '@utils';
import { Link } from '@elements/Link';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import clsx from 'clsx';

type ButtonCTAProps = {
  __typename: string;
  contentful_id: string;
  title: string;
  ctaLink: string;
  color: string;
  position: string;
};

type NodeProps = {
  nodeType: string;
  content: [];
  data: {
    target: {
      sys: {
        id: string;
        linkType: string;
        type: string;
      };
    };
  };
};

type ContentfulItemProps = {
  __typename?: string;
  contentful_id?: string;
  title?: string;
  file?: {
    title: string;
    url: string;
    contentType: string;
  };
  gatsbyImageData?: IGatsbyImageData;
  ctaLink?: string;
  color?: string;
  position?: string;
  size?: string;
};

type ReferencesProps = Array<ButtonCTAProps | any>;

type RichtextProps = {
  bodyRichText: {
    raw: string;
    references?: ReferencesProps;
  };
  classes?: string;
  compact?: boolean;
  useHeadings?: boolean;
};

const assetEmbed = (node: NodeProps, references: ReferencesProps): JSX.Element => {
  // Set default screen width to 769 for backwards compatibility
  // with undefined < 768 being false.
  const { width } = useScreenSize(769);

  if (node?.data.target.sys.linkType === 'Asset') {
    const found: ContentfulItemProps = references?.find(({ contentful_id }) => contentful_id === node?.data.target.sys.id);
    // eslint-disable-next-line no-underscore-dangle
    switch (found?.__typename) {
      case 'ContentfulAsset': {
        const type = found?.file.contentType;

        const isImage = type.search('image') >= 0;
        if (isImage) {
          const image = getImage(found?.gatsbyImageData);
          if (image) {
            return (
              <figure>
                <GatsbyImage alt={found.title} image={image} />
              </figure>
            );
          }
          return (
            <figure>
              <img alt={found.title} className='mx-auto' src={found.file.url} />
            </figure>
          );
        }

        const isPdf = type === 'application/pdf';
        const isMobile = width < 768;
        if (isPdf) {
          return(
            <div className='iframe-wrapper--pdf'>
              <iframe
                src={isMobile ? `https://drive.google.com/viewerng/viewer?embedded=true&url=https:` + encodeURIComponent(found?.file?.url): found?.file?.url}
                title={found?.title || 'PDF viewer'}
                width={'fit'}
                height={'fit'}
              >
                <p>This browser does not support PDF!</p>
              </iframe>
            </div>
          );
        }
        return <></>;
      }
      default: {
        return <></>;
      }
    }
  }

  return <></>;
};

const entryEmbed = (node: NodeProps, references: ReferencesProps): JSX.Element => {
  if (node?.data.target.sys.linkType === 'Entry') {
    const found: ContentfulItemProps = references?.find(({ contentful_id }) => contentful_id === node?.data.target.sys.id);
    // eslint-disable-next-line no-underscore-dangle
    switch (found?.__typename) {
      case 'ContentfulButtonCta': {
        const { title, ctaLink, color, position, size } = found;

        const classes = getClasses(`btn--cta-${position}`, `btn--${size}`, `btn--cta`);

        return (
          <div className={classes}>
            <Button childClassName={`btn--${color}`} href={ctaLink}>
              {title}
            </Button>
          </div>
        );
      }
      case 'ContentfulWrapperText': {
        return (
          <>
            <ArticleWrappedBody>
              {found}
            </ArticleWrappedBody>
          </>
        );
      }
      default: {
        return <></>;
      }
    }
  }
  return <></>;
};

const codeEntry = (node: string): JSX.Element => {
  if (isIframe(node)) {
    const width = getParamValue(node, 'width');
    const height = getParamValue(node, 'height');
    const src = getParamValue(node, 'src');
    const title = getParamValue(node, 'title');
    const allow = getParamValue(node, 'allow');
    const style = getParamValue(node, 'style');

    return (
      style ? 
        <div className='iframe-wrapper--video' style={{marginBottom:"20px"}}>
          <iframe allow={allow} allowFullScreen frameBorder='0' height={height} src={src} title={title} style={{width:"100%"}} />
        </div> :
        <div className='iframe-wrapper--video'>
          <iframe allow={allow} allowFullScreen frameBorder='0' height={height} src={src} title={title} width={width} />
        </div>
    );
  }

  return <></>;
};

const paragraphRender = (node: NodeProps, children: JSX.Element): JSX.Element => {
  if (isIframe(node)) {
    return children;
  }
  return <p>{children}</p>;
};

const entryHyperLink = (node: NodeProps, children: JSX.Element, references: ReferencesProps): JSX.Element => {
  if (node?.data.target.sys.linkType === 'Entry' && node?.data.target.sys.type === 'Link') {
    const found = references?.find(({ contentful_id }) => contentful_id === node?.data.target.sys.id);
    if (found) {
      let link;
      // eslint-disable-next-line no-underscore-dangle
      switch (found?.__typename) {
        case 'ContentfulArticle': {
          link = `/${found?.type?.toLowerCase()}/${found?.slug}/`;
          break;
        }
        case 'ContentfulPage': {
          link = `/${found?.slug}/`;
          break;
        }
        default: break;
      }
      return link && <Link to={link}>{children}</Link>;
    }
  }
  return <>{children}</>;
};

const renderOptions = (
  references: ReferencesProps,
  compact: boolean,
  useHeadings: boolean
) => {
  const headings = useHeadings
    ? {
        [BLOCKS.HEADING_1]: (node, children) => (
          <h1
            className={clsx(compact && "leading-none", "font-bold text-rt-xl md:text-xxl mb-3")}>
            {children}
          </h1>
        ),
        [BLOCKS.HEADING_2]: (node, children) => (
          <h2 className={clsx(compact && "leading-none", "text-rt-l md:text-rt-xl mb-3")}>
            {children}
          </h2>
        ),
        [BLOCKS.HEADING_3]: (node, children) => (
          <h3 className={clsx(compact && "leading-none", "text-rt-m md:text-rt-l mb-3")}>
            {children}
          </h3>
        ),
      }
    : {};

  return {
    renderNode: {
      ...headings,
      [BLOCKS.PARAGRAPH]: (node, children) =>
        children?.toString().trim() === ""
          ? null
          : paragraphRender(node, children),
      [BLOCKS.EMBEDDED_ENTRY]: (node) => entryEmbed(node, references),
      [BLOCKS.EMBEDDED_ASSET]: (node) => assetEmbed(node, references),
      [INLINES.ENTRY_HYPERLINK]: (node, children) =>
        entryHyperLink(node, children, references),
    },
    renderMark: {
      [MARKS.CODE]: (node) => codeEntry(node),
    },
  }
};

const RichText = ({
  bodyRichText: { raw, references },
  classes = '',
  compact = false,
  useHeadings = false,
}: RichtextProps): JSX.Element => {
  try {
    const richText = JSON.parse(raw);
    const mainClasses = `rich-text__container ${classes}`;

    return (
      <div className={mainClasses}>
        {documentToReactComponents(
          richText,
          renderOptions(references, compact, useHeadings)
        )}
      </div>
    );
  } catch (error) {
    console.log("Error rendering Rich Text", error);
    return null;
  }
};

export default RichText;
