type GetClassesFn = (
  classes: string[]
) => string;

// eslint-disable-next-line import/prefer-default-export
export const getClasses: GetClassesFn = (...classes) => {
  let newClass = '';
  classes.forEach( oneClass => {
    if(oneClass)
      newClass += ` ${oneClass}`;
  });
  return newClass;
};
