import { IGatsbyImageData } from "gatsby-plugin-image";

type ImageProps = {
  title: string,
  gatsbyImageData: IGatsbyImageData,
};

type ContentfulItemProps = {
  contentful_id: string,
  title: string,
  slug: string,
  publishDate: string,
};

type ArticleProps = {
  contentful_id: string,
  title: string,
  slug: string,
  type?: string,
  publishDate: string,
  categories: Array<ContentfulItemProps>,
};

type HeroInfoProps = {
  item: {
    image: ImageProps,
    background: string,
  },
  extra: {
    heroHeader: {
      useHr?: boolean,
      category: string,
      date: string,
      title: string,
      subtitle?: string,
      buttonText: string,
      buttonSlug: string,
    },
  },
};

type GetHeroInfoFn = (
  image: ImageProps,
  blog: ArticleProps,
  title: string,
  subtitle: string,
  useFilter: boolean,
  dummy: boolean,
) => HeroInfoProps;

// eslint-disable-next-line import/prefer-default-export
export const getHeroInfo: GetHeroInfoFn = ( image, blog, title, subtitle, useFilter, dummy ) => {
  let heroHeader;

  let ctaText = null
  dummy ? ctaText = "Read our latest article" : ctaText = "Read article" 

  if(blog){
    heroHeader = {
      category: blog.categories[0].title,
      date: blog.publishDate,
      title: blog.title,
      buttonText: ctaText,
      buttonSlug: `/${ blog.type.toLowerCase() }/${blog.slug}/`,
    };
  }

  if(title || subtitle){
    heroHeader = {
      useHr: true,
      title,
      subtitle,
    };
  }

  return {
    item: {
      image,
      background: 'image',
      useFilter,
    },
    extra: {
      heroHeader
    },
  };

};
