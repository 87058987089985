import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { Link } from "@elements/Link";
import type { ContentfulNavigationNavMenu } from "@src/graphql/modules/navigation";
import RichText from "@src/components/elements/RichText";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const NavItem = ({ item, doCloseMenu }) => {
  return (
    <Link
      onClick={doCloseMenu}
      key={item.contentful_id}
      to={item.page?.slug ? `/${item.page.slug}` : item.url}
      className="flex gap-4"
    >
      {item.icon && item.icon?.file?.contentType !== "image/svg+xml" && (
        <GatsbyImage
          className="w-24px h-24px"
          image={getImage(item.icon)}
          alt={item.icon?.title}
        />
      )}
      {item.icon?.file?.contentType === "image/svg+xml" && (
        <img
          className="w-24px h-24px"
          src={item.icon.file.url}
          alt={item.icon?.title}
        />
      )}
      <div>
        <p className="font-bold">{item.label}</p>
        <p className="text-grey-desc text-rt-s">{item.shortDescription}</p>
      </div>
    </Link>
  );
};

const HeaderMenuItemContent = ({
  label,
  items,
  doCloseMenu,
}: {
  label: string;
  items: ContentfulNavigationNavMenu["items"];
  doCloseMenu: () => void;
}): JSX.Element => (
  <div className="animate-fadeIn p-2">
    {items.at(0).__typename !== "ContentfulNavigationNavMenu" && (
      <p className="pt-2 text-rt-xs uppercase text-neutral-8 font-bold tracking-wider">
        {label}
      </p>
    )}
    <div className="mt-2 flex flex-col gap-5">
      {items.map((item, idx) => {
        if (item.__typename === "ContentfulNavigationNavItem") {
          return (
            <NavItem
              key={item.contentful_id}
              item={item}
              doCloseMenu={doCloseMenu}
            />
          );
        }
        if (item.__typename === "ContentfulNavigationNavMenu") {
          return (
            <div className="submenu" key={item.contentful_id}>
              <p className="label pb-4 text-rt-xs uppercase text-neutral-8 font-bold tracking-wider">
                {item.label}
              </p>
              <div className="items-wrapper flex flex-col gap-5">
                {item.items?.map((subItem) => (
                  <NavItem
                    key={item.contentful_id}
                    item={subItem}
                    doCloseMenu={doCloseMenu}
                  />
                ))}
              </div>
              {items?.at(idx + 1)?.__typename ===
                "ContentfulNavigationNavMenu" && <hr className="mt-5" />}
            </div>
          );
        }
        if (item.__typename === "ContentfulGenericCard") {
          return (
            <div
              key={item.contentful_id}
              className="mt-6 rounded-2xl bg-grey-smooth p-6 max-w-fit"
            >
              <p className="mb-4 text-rt-xs uppercase text-neutral-8 font-bold tracking-wider">
                {item.headline}
              </p>
              <GatsbyImage
                className="bg-accent-4 rounded-xl"
                image={getImage(item.image)}
                alt={item.image?.title}
              />
              {item.body && (
                <p className="w-6/12 font-bold mt-6">
                  <RichText bodyRichText={item.body} />
                </p>
              )}
              {item.cta?.at(0)?.label && item.cta?.at(0)?.url && (
                <Link
                  onClick={doCloseMenu}
                  to={item.cta.at(0)?.url}
                  className="flex items-center gap-2 mt-3 text-accent-9-alpha text-rt-s font-bold transition-all hover:gap-4 hover:text-accent-10-alpha focus:gap-4 focus:text-accent-10-alpha"
                >
                  {item.cta.at(0)?.label}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M8.66667 4.66675L12 8.00008M12 8.00008L8.66667 11.3334M12 8.00008L4 8.00008"
                      stroke="#1AA963"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Link>
              )}
            </div>
          );
        }
        return null;
      })}
    </div>
  </div>
);

const HeaderMenuItem = ({
  item,
  doCloseMenu,
}: {
  item: ContentfulNavigationNavMenu["items"][0];
  doCloseMenu: () => void;
}): JSX.Element => {
  return (
    <div className="relative" tabIndex={0}>
      {item.__typename === "ContentfulNavigationNavMenu" && (
        <HeaderMenuItemContent
          label={item.label}
          items={item.items}
          doCloseMenu={doCloseMenu}
        />
      )}
      {item.__typename === "ContentfulNavigationNavItem" &&
        (item.url || item.page) && (
          <Link
            onClick={doCloseMenu}
            className="flex text-new-black items-center gap-2 rounded-lg transition-color"
            to={item.page?.slug ? `/${item.page.slug}` : item.url}
          >
            {item.label}
          </Link>
        )}
    </div>
  );
};

const HeaderMenuMobile = ({
  navMenu,
}: {
  navMenu: ContentfulNavigationNavMenu;
}): JSX.Element => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (isActive) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }

    return () => {
      document.body.style.overflow = "visible";
    };
  }, [isActive]);

  return (
    <div className="lg:hidden">
      <button
        className="w-32px h-32px relative z-30 overflow-hidden"
        onClick={() => {
          setIsActive(!isActive);
        }}
      >
        <span
          className={clsx(
            "w-full h-4px absolute left-0 top-1 bg-black-andel rounded-lg transition-all",
            isActive && "-rotate-45 !top-14px"
          )}
        />
        <span
          className={clsx(
            "w-full h-4px absolute left-0 top-13px bg-black-andel rounded-lg transition-opacity",
            isActive && "opacity-0"
          )}
        />
        <span
          className={clsx(
            "w-full h-4px absolute left-0 top-22px bg-black-andel rounded-lg transition-all",
            isActive && "rotate-45 !top-14px"
          )}
        />
      </button>

      {isActive && (
        <div className="fixed inset-0 z-20 bg-accent-2">
          <div className="h-20 w-full" />

          <div className="p-8 pt-2 h-full-woHeader flex flex-col justify-between overflow-y-auto">
            <div className="flex flex-col gap-4">
              {navMenu.items?.map((item) => (
                <HeaderMenuItem
                  key={item.contentful_id}
                  item={item}
                  doCloseMenu={() => setIsActive(false)}
                />
              ))}
            </div>

            <div className="flex flex-col gap-3 mt-8">
              <Link
                to="https://platform.reptrak.com/"
                className="justify-center flex text-new-black items-center gap-2 py-1 rounded-lg transition-colors hover:underline focus:underline"
              >
                Log In
              </Link>

              <Link
                to="/contact"
                className="justify-center btn-new btn-new--small btn-new--outline btn-new--black"
              >
                Contact sales
              </Link>

              <Link
                to="/request-demo"
                className="justify-center btn-new btn-new--small"
              >
                Book a demo
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HeaderMenuMobile;
