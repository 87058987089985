type ContentfulItemProps = {
  contentful_id: string,
  title: string,
  slug: string,
  publishDate: string,
};

type ReorderArticlesFn = (
  list: {
    edges: Array<{
      node: ContentfulItemProps,
    }>;
  },
) => ContentfulItemProps[];

// eslint-disable-next-line import/prefer-default-export
export const reorderArticles: ReorderArticlesFn = ( list ) => list?.edges.map(({ node }) => node).sort((a,b)=>{
  const dateA = new Date(a.publishDate).getTime();
  const dateB = new Date(b.publishDate).getTime();
  return dateB - dateA;
});
