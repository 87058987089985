import React, { useState } from "react";
import clsx from "clsx";
import { Link } from "@elements/Link";
import ArrowDown from "@svg/icons/arrow-down.svg";
import type { ContentfulNavigationNavMenu } from "@src/graphql/modules/navigation";
import RichText from "@src/components/elements/RichText";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const NAVIGATION_NAV_MENU = "ContentfulNavigationNavMenu";
const NAVIGATION_NAV_ITEM = "ContentfulNavigationNavItem";
const GENERIC_CARD = "ContentfulGenericCard";

export function groupNavMenus(items) {
  return items.reduce((acc, item, idx) => {
    if (
      item.__typename === NAVIGATION_NAV_MENU &&
      items?.[idx + 1]?.__typename === NAVIGATION_NAV_MENU
    ) {
      let i = 1;
      while (items?.[idx + i]?.__typename === NAVIGATION_NAV_MENU) {
        i++;
      }
      return [...Array.from(acc), items.slice(idx, idx + i)];
    }
    if (
      item.__typename === NAVIGATION_NAV_MENU &&
      items[idx - 1].__typename === NAVIGATION_NAV_MENU
    ) {
      // skip
      return acc;
    }
    return [...Array.from(acc), item];
  }, []);
}

const NavItem = ({ item, doClose }) => (
  <Link
    onClick={doClose}
    key={item.contentful_id}
    className="navigation-item flex gap-4 transition-all hover:text-accent-10-alpha focus:text-accent-10-alpha"
    to={item.page?.slug ? `/${item.page.slug}` : item.url}
  >
    {item.icon?.file?.contentType !== "image/svg+xml" && item.icon && (
      <GatsbyImage
        className="icon-svg w-24px h-24px"
        image={getImage(item.icon)}
        alt={item.icon.title}
      />
    )}
    {item.icon?.file?.contentType === "image/svg+xml" && (
      <img
        className="icon-img w-24px h-24px"
        src={item.icon.file.url}
        alt={item.icon.title}
      />
    )}
    <div>
      <p className="menu-item-label font-bold">{item.label}</p>
      <p className="menu-item-description text-grey-desc text-rt-s max-w-[200px]">
        {item.shortDescription}
      </p>
    </div>
  </Link>
);

const HeaderMenuItemContent = ({
  label,
  items,
  doClose,
}: {
  label: string;
  items: ContentfulNavigationNavMenu["items"];
  doClose: () => void;
}): JSX.Element => {
  const groupedItems = groupNavMenus(items);
  return (
    <div
      className={clsx(
        "animate-fadeIn w-max p-6 bg-white rounded-2xl shadow-menuDropdown absolute z-10 right-3 top-10",
        "translate-x-1/2"
      )}
    >
      <div className="notch rotate-45 w-24px h-24px bg-white mx-auto -mt-8" />
      {groupedItems.at(0).__typename !== NAVIGATION_NAV_MENU &&
        !Array.isArray(groupedItems.at(0)) && (
          <p className="dialog-label pb-4 text-rt-xs uppercase text-neutral-8 font-bold tracking-wider pt-2">
            {label}
          </p>
        )}
      <div className="items-wrapper flex flex-col gap-5">
        {groupedItems.map((item) => {
          if (item.__typename === NAVIGATION_NAV_ITEM) {
            return (
              <NavItem key={item.contentful_id} item={item} doClose={doClose} />
            );
          }
          if (item.__typename === GENERIC_CARD) {
            return (
              <div
                key={item.contentful_id}
                className={clsx(
                  "mt-2 rounded-2xl bg-grey-smooth p-6 -mx-2 -mb-2",
                  "featured-menu-card"
                )}
              >
                <p className="mb-4 text-rt-xs uppercase text-neutral-8 font-bold tracking-wider">
                  {item.headline}
                </p>
                <GatsbyImage
                  className="w-full object-contain bg-accent-4 rounded-xl"
                  image={getImage(item.image)}
                  alt={item.image.title}
                />
                {item.body && (
                  <p className="w-6/12 font-bold mt-6">
                    <RichText bodyRichText={item.body} />
                  </p>
                )}
                {item.cta?.at(0)?.label && item.cta?.at(0)?.url && (
                  <Link
                    to={item.cta.at(0)?.url}
                    onClick={doClose}
                    className="flex items-center gap-2 mt-3 text-accent-9-alpha text-rt-s font-bold transition-all hover:gap-4 hover:text-accent-10-alpha focus:gap-4 focus:text-accent-10-alpha"
                  >
                    {item.cta.at(0)?.label}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M8.66667 4.66675L12 8.00008M12 8.00008L8.66667 11.3334M12 8.00008L4 8.00008"
                        stroke="#1AA963"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Link>
                )}
              </div>
            );
          }
          if (item.__typename === NAVIGATION_NAV_MENU) {
            return (
              <div className="submenu" key={item.contentful_id}>
                <p className="label pb-4 text-rt-xs uppercase text-neutral-8 font-bold tracking-wider">
                  {item.label}
                </p>
                <div className="items-wrapper flex flex-col gap-5">
                  {item.items?.map((subItem) => (
                    <NavItem
                      key={item.contentful_id}
                      item={subItem}
                      doClose={doClose}
                    />
                  ))}
                </div>
              </div>
            );
          }
          if (Array.isArray(item)) {
            return (
              <div
                key={item.map((i) => i.contentful_id).join("-")}
                className="first-wrap flex gap-5 flex-row px-1"
              >
                {item.map((subItem, idx) => (
                  <div key={`subitem-${idx}`}>
                    <p className="label pb-4 text-rt-xs uppercase text-neutral-8 font-bold tracking-wider pt-2">
                      {subItem.label}
                    </p>
                    <div
                      className={clsx(
                        idx > 0 && "border-l-[1px] border-gray-200 pl-8"
                      )}
                    >
                      <div className="items-wrapper flex flex-col gap-5">
                        {subItem.items?.map((subItem) => (
                          <NavItem
                            key={item.contentful_id}
                            item={subItem}
                            doClose={doClose}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            );
          }

          return null;
        })}
      </div>
    </div>
  );
};

const HeaderMenuItem = ({
  item,
}: {
  item: ContentfulNavigationNavMenu["items"][0];
}): JSX.Element => {
  const [isActive, setIsActive] = useState(false);
  const [mouseLeft, setMouseLeft] = useState(true);

  return (
    <div
      className="relative"
      tabIndex={0}
      onBlur={() => mouseLeft && setIsActive(false)}
    >
      {item.__typename === NAVIGATION_NAV_MENU && (
        <>
          <button
            className="flex items-center gap-1 cursor-pointer"
            onClick={() => {
              setIsActive(!isActive);
            }}
          >
            {item.label}
            <div
              className={clsx(
                "h-6 w-6 transition-transform",
                isActive && "rotate-180"
              )}
            >
              <ArrowDown />
            </div>
          </button>
          <div
            className={clsx(!isActive && "hidden")}
            onMouseEnter={() => setMouseLeft(false)}
            onMouseLeave={() => setMouseLeft(true)}
          >
            <HeaderMenuItemContent
              label={item.label}
              items={item.items}
              doClose={() => setIsActive(false)}
            />
          </div>
        </>
      )}
      {item.__typename === NAVIGATION_NAV_ITEM && (item.url || item.page) && (
        <Link
          className="flex text-new-black items-center gap-2 mr-1 px-2 py-1 rounded-lg transition-colors hover:bg-neutral-7-alpha focus:bg-neutral-7-alpha"
          to={item.page?.slug ? `/${item.page.slug}` : item.url}
        >
          {item.label}
        </Link>
      )}
    </div>
  );
};

const HeaderMenuDesktop = ({
  navMenu,
}: {
  navMenu: ContentfulNavigationNavMenu;
}): JSX.Element => (
  <nav className="text-black-andel hidden lg:flex items-center gap-10 grow">
    {navMenu?.items?.map((item) => {
      if (
        item.__typename === NAVIGATION_NAV_MENU ||
        item.__typename === NAVIGATION_NAV_ITEM
      ) {
        return (
          item.label && <HeaderMenuItem key={item.contentful_id} item={item} />
        );
      } else null;
    })}
  </nav>
);

export default HeaderMenuDesktop;
