import React from 'react';

import { Link as GatsbyLink } from 'gatsby';

export function Link({ to, children, ...props }) {

  if (!to) {
    return <span {...props}>{children}</span>;
  }

  let modifiedTo = to;

  if (to.match(/(^(\/?)homepage(\/?)$|^(\/?)homepage\/(.*))/)) {
    modifiedTo = "/";
  }

  // Enforces client side redirects from old rankings page to new globalreptrak page.
  // This is a temporary solution until we can update all the links in the site.
  // @todo: Remove this when all links are updated.
  if (to.match(/(^(\/?)rankings(\/?)$|^(\/?)rankings\/(.*))/)) {
    to = "/globalreptrak/";
  }

  if (to.match(/(^http\/(.*))/)) {
    return (
      <a href={modifiedTo} {...props}>
        {children}
      </a>
    );
  }

  return (
    <GatsbyLink to={modifiedTo} {...props}>
      {children}
    </GatsbyLink>
  );
}