
type GetReputationScoreLabelFn = (
  score: number,
) => string;

// eslint-disable-next-line import/prefer-default-export
export const getReputationScoreLabel: GetReputationScoreLabelFn = (
  score,
) => {
  let label = '';
  if (score >= 80)      label = 'Excellent';
  else if (score >= 70) label = 'Strong';
  else if (score >= 60) label = 'Average';
  else if (score >= 40) label = 'Weak';
  else if (score >= 0)  label = 'Poor';
  
  return label;
};
