
type GetPageListFn = (
  current: number, 
  max: number, 
  isRanking: boolean
) => string[];

// eslint-disable-next-line import/prefer-default-export
export const getPageList: GetPageListFn = (
  current= 1,
  max= 1,
  mdSize= false,
  isRanking= false,
) => {
  let pageList = [];

  if(!isRanking){
    if( current > 3 ){
      pageList = [ 'l', '1', '...' ];
    }else if( current > 2 ){
      pageList = [ 'l', '1' ];
    }else if( current > 1 ){
      pageList = [ 'l' ];
    }
  
    for(let i = current-1; i <= current+1; i+=1){
      if( i > 0 && max >= i ) pageList.push(`${i}`);
    }
  
    if( max - 2 > current ){
      pageList = [ ...pageList, '...', `${max}`, 'r' ];
    }else if( max - 1 > current ){
      pageList = [ ...pageList, `${max}`, 'r' ];
    }else if( max > current ){
      pageList = [ ...pageList, 'r' ];
    }
  }else{
    if( current > 1 )
      pageList = [ 'l' ];

    if(!mdSize){
      for (let i = 0; i < max; i+=1) 
        pageList.push(`${i + 1}`);
    }

    if( max > current )
      pageList = [ ...pageList, 'r' ];
    
  }

  return pageList;
};
