import { HTMLProps } from 'react';

type InputProps = HTMLProps<HTMLAllCollection> & { 
  labels: { 
    textContent: string,
  }, 
  value: string,
};

type FormRefProps = {
  current: {
    el: {
      firstChild: InputProps[],
    },
  },
};

type IconsProps = {
  hubspotId: string,
  iconId: string,
};

// eslint-disable-next-line import/prefer-default-export
export const changeTextPlaceholder = ( formReference: FormRefProps, icons: IconsProps[]): void => {
  const form: InputProps[] = Array.from(formReference.current.el.firstChild);
  const onlyInputs = form.filter( (inp) => inp.className.match('hs-input') );

  for (let i = 0; i < onlyInputs.length; i += 1) {
    let icon = "\uf069";
    const findIcon = icons.find(({ hubspotId }) => hubspotId.substr(3) === onlyInputs[i].name );
    if(findIcon) icon = String.fromCharCode(parseInt(findIcon.iconId, 16));

    const text = onlyInputs[i].labels[0].textContent;
    onlyInputs[i].placeholder = `${ icon }   ${ text }`;
  }
};
