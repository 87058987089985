import React from 'react';

import { Link } from '@elements/Link';

import './style.css';

type ButtonProps = {
  children?: React.ReactNode;
  childClassName?: string;
  href?: string;
  type?: string;
  ariaLabel?: string;
  ariaCurrent?: string;
  onClick?: () => void;
};

const Button = ({
  children,
  childClassName = '',
  href,
  type,
  onClick,
  ariaLabel,
  ariaCurrent,
}: ButtonProps): JSX.Element => {
  let JSXTag: JSX.Element | string | any = href ? 'a' : 'button';
  let toLink;
  let toHref;
  let toTarget;
  let rel;

  if (href) {
    if (/^\/(?!\/)|#/.test(href)) {
      JSXTag = Link;
      toLink = href;
    } else {
      toHref = href;
      toTarget = '_blank';
      rel = 'noreferrer noopener';
    }
  }

  return (
    <JSXTag
      aria-current={ariaCurrent}
      aria-label={ariaLabel}
      className={`btn ${childClassName}`}
      href={toHref}
      rel={rel}
      target={toTarget}
      to={toLink}
      type={type}
      onClick={onClick}
    >
      {children}
    </JSXTag>
  );
};

export default Button;
