type MoveSlidesFn = (
  reference: React.MutableRefObject<any>,
  right: boolean,
  left: boolean,
  timeMove: number,
  btnPressed: boolean,
  setBtnPressed: React.Dispatch<React.SetStateAction<boolean>>,
) => void;

// eslint-disable-next-line import/prefer-default-export
export const moveSlides: MoveSlidesFn = (
  reference,
  right,
  left,
  transitionTime = 500,
  btnPressed,
  setBtnPressed,
) => {
  if((right || left ) && !btnPressed){
    setBtnPressed(true);
    const container = reference.current.firstChild;
    const itemWidth = container.firstChild.offsetWidth;
    let moveQty;
    if(right) moveQty = 2;
    if(left)  moveQty = 0;

    const transitionEnd = () => {
      if(right){
        const firstChild = container?.firstChild;
        container.appendChild(firstChild);
      }
      if(left){
        const lastChild = container?.lastChild;
        container.insertBefore(lastChild, container.childNodes[0]); 
      }
      setBtnPressed(false);

      container.style.transition = 'none';
      container.style.transform = `translate(-${itemWidth}px)`;
    };

    container.style.transition = `${transitionTime}ms ease-out all`;
    container.style.transform = `translateX(-${itemWidth * moveQty}px)`;
    setTimeout(transitionEnd, transitionTime);
  }
};
