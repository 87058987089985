import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import { Link } from "@elements/Link";
import LogoDarkSvg from "@svg/logos/reptrak-black.svg";
import HeaderMenuDesktop from "./HeaderMenuDesktop";
import HeaderMenuMobile from "./HeaderMenuMobile";
import type { ContentfulNavigationNavMenu } from "@src/graphql/modules/navigation";

const NewHeader = ({
  navMenu,
}: {
  navMenu: ContentfulNavigationNavMenu;
}): JSX.Element => {
  const [isSticky, setIsSticky] = useState(false);
  const targetRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsSticky(entry.intersectionRatio < 1);
      },
      {
        threshold: [1],
      }
    );

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, []);

  return (
    <header
      ref={targetRef}
      className={clsx(
        "font-atkinson sticky -top-1px z-50 bg-accent-2 transition-all",
        isSticky && "shadow-default"
      )}
    >
      <div className="inner flex items-center justify-between !px-8 lg:!px-4">
        <div className="flex items-center gap-16 justify-between lg:justify-start w-full lg:w-auto">
          <Link
            aria-current="page"
            aria-label="logo-reptrak"
            to="/"
            className="relative z-30"
          >
            <LogoDarkSvg className="max-w-[120px] w-40 h-20" />
          </Link>
          <HeaderMenuDesktop navMenu={navMenu} />
          <HeaderMenuMobile navMenu={navMenu} />
        </div>
        <div className="gap-3 items-center hidden lg:flex">
          <Link
            to="https://platform.reptrak.com/"
            className="flex text-new-black items-center gap-2 mr-1 px-2 py-1 rounded-lg transition-colors hover:underline focus:underline"
          >
            Log In
          </Link>

          <Link
            to="/contact"
            className="btn-new btn-new--small btn-new--outline btn-new--black"
          >
            Contact sales
          </Link>

          <Link to="/request-demo" className="btn-new btn-new--small">
            Book a demo
          </Link>
        </div>
      </div>
    </header>
  );
};

export default NewHeader;
